<template>

    <!-- Promotion Prize Information Modal -->
    <modal-edit-promotion-prize
        v-if="modalPrizeOpen"
        :editPermission="editPermission"
        :item="item"
        :prize="prize"
        @openModalDeterminingInformation="openModalDeterminingInformation"
        @save="saveModalPrize"
        @close="closeModalPrize"
    />

    <!-- Prizes List -->
    <div class="step__table three-columns">
        <div class="table three-columns">
            <div class="table__row title">
                <p class="table__cell">{{ $t('PrizeText.name') }}</p>
                <p class="table__cell">{{ $t('PrizeText.category') }}</p>
                <p class="table__cell">{{ $t('TableHeaders.action') }}</p>
            </div>
            <div class="table__body">
                <div
                    v-for="prizeItem in item.prizes" :key="prizeItem.id"
                    class="table__row"
                    :class="(prize && prize.id === prizeItem.id) ? 'table__row__selected' : 'table__row'"
                    style="cursor: pointer;"
                    @click="changeCurrentID(prizeItem)"
                >
                    <div style="text-align: left; margin-left: -150px;">
                        <p>
                          {{ prizeItem.full_name }}
                        </p>
                    </div>
                    <div class="table__cell">
                        <p>
                          {{ PrizesTypeText(prizeItem.type) }}
                        </p>
                    </div>
                    <div class="table__cell button">
                        <div class="table__cell-button" :title="$t('Buttons.delete')" @click="deletePrize(prizeItem)">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0001 3.12868e-08C10.8855 -0.000110722 11.7389 0.293826 12.3928 0.824097C13.0467 1.35437 13.4539 2.08266 13.5344 2.86599H19.0325C19.2777 2.86605 19.5138 2.94881 19.6929 3.09753C19.8721 3.24625 19.981 3.44984 19.9977 3.66718C20.0145 3.88451 19.9377 4.09938 19.783 4.26837C19.6282 4.43736 19.4071 4.54786 19.1642 4.57755L19.0325 4.58558H18.2803L16.6454 17.173C16.5726 17.7317 16.2711 18.2472 15.7984 18.6214C15.3257 18.9955 14.7146 19.2022 14.0815 19.2021H5.9188C5.28569 19.2022 4.67464 18.9955 4.20189 18.6214C3.72915 18.2472 3.4277 17.7317 3.35489 17.173L1.71874 4.58558H0.967756C0.733898 4.58557 0.507953 4.51033 0.331707 4.37376C0.155461 4.2372 0.0408371 4.04855 0.00903235 3.84272L0 3.72578C9.86195e-06 3.51801 0.0847014 3.31727 0.238413 3.16069C0.392124 3.00411 0.604456 2.90227 0.836141 2.87401L0.967756 2.86599H6.4659C6.54639 2.08266 6.95356 1.35437 7.60747 0.824097C8.26139 0.293826 9.1148 -0.000110722 10.0001 3.12868e-08ZM8.06464 7.16497C7.7485 7.16497 7.48398 7.34266 7.42979 7.57652L7.41946 7.66709V14.6888L7.42979 14.7782C7.48398 15.012 7.7485 15.1897 8.06464 15.1897C8.38077 15.1897 8.64529 15.012 8.69948 14.7782L8.70981 14.6876V7.66823L8.69948 7.57652C8.64529 7.3438 8.38077 7.16497 8.06464 7.16497ZM11.9357 7.16497C11.6195 7.16497 11.355 7.34266 11.3008 7.57652L11.2905 7.66709V14.6888L11.3008 14.7782C11.355 15.012 11.6195 15.1897 11.9357 15.1897C12.2518 15.1897 12.5163 15.012 12.5705 14.7782L12.5808 14.6876V7.66823L12.5705 7.57652C12.5163 7.3438 12.2518 7.16611 11.9357 7.16611V7.16497ZM10.0001 1.71959C9.21949 1.71959 8.56787 2.21254 8.41948 2.86599H11.5808C11.4311 2.21254 10.7808 1.71959 10.0001 1.71959Z" fill="#BDBDBD"/>
                            </svg>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>

</template>

<script>
import InputsMixin from "@/mixins/inputsEvent";

import {
  PrizesTypeText,

  DrawingType,
  PrizesType,
  CertificateStatus,
} from "@/models/prizes";

import ModalEditPromotionPrize from '@/components/ModalEditPromotionPrize'

export default {
  name: 'NewPromotionStepFourthSubStepFifth',
  emits: [
    "step",
    "updatePromotion",
    "openModalDeterminingInformation",
  ],
  components: {
    ModalEditPromotionPrize,
  },
  mixins: [InputsMixin],
  props: {
    editPermission: Boolean,
    promotion: Object,
  },
  data() {
      return {
        PrizesType,
        CertificateStatus,
        DrawingType,

        item: {},
        prize: null,
        modalPrizeOpen: false,

        examplePrize: null,

        requiredSelectRules: [(v) => !!v || 'Выберите значение'],
      }
  },
  watch: {
    promotion: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler () {
        this.item = this.promotion

        // add stup prizes to view UI
        // this.item.prizes = this.item.prizes.concat(this.prizes)
        // console.log(this.item.prizes)
      }
    },
  },
  computed: {
  },
  methods: {
    PrizesTypeText,

    changeCurrentID(item) {
      if(item) {
        if(!this.prize || this.prize.id !== item.id)
          this.prize = this.item.prizes.find(p => p.id === item.id)
        else
          this.prize = null
      }
      else
        this.prize = null

      if(this.prize && !this.prize.drawingEveryday) {
        this.prize.drawingEveryday = {
          // date
          applicatonForPromoStartedAt: null,
          // date
          applicatonForPromoEndedAt: null,
          // date
          drawingPromoAt: null,
          // integer
          numberOfPrizes: 1,
          // bool
          moveRemainingPrizesToNextDrawing: false,
        }
      }

      this.examplePrize = null

      this.modalPrizeOpen = !!this.prize
    },

    saveModalPrize(item) {
      if (item) {
        if (this.item && this.item.prizes) {
          const storeIndex = this.item.prizes.findIndex((prize) => prize.id === item.id)
          const indexItem = this.item.prizes[storeIndex]
          this.item.prizes[storeIndex] = {...indexItem, ...item}
        }
      }

      this.modalPrizeOpen = false
      this.prize = null
    },

    closeModalPrize() {
      this.modalPrizeOpen = false
      this.prize = null
    },

    deletePrize(prize) {
      if(prize) {
        if (
            confirm(this.$t('PrizeText.delete'))
        ) {
          this.item.prizes = this.item.prizes.filter(p => p.id !== prize.id)

          if(this.prize && this.prize.id === prize.id) {
            process.nextTick(() => {
              this.prize = null
            });
          }

          this.$emit("updatePromotion", this.item)

          this.$notify({
            type: "success",
            title: this.$t('Errors.success_operation'),
            text: this.$t('PrizeText.delete_success'),
          });
        }
      }
    },

    openModalDeterminingInformation(determining) {
      this.$emit("openModalDeterminingInformation", determining)
    },

  },
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.dd {
  position: relative;
  width: 100%;
  &.object {
    width: 265px !important;
    margin-top: 0px !important;
    font-size: 12px;
    font-weight: 500;
    // .dd-button {
    //   height: 27px !important;
    // }
  }
  .dd-button {
    @include ui-dropdown-button();
    border-radius: 0px 5px 5px 0px;
  }
  & ::v-deep .vs__dropdown-toggle {
    border: 1px solid $inputCaption;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
}

.date-picker {
  align-items: center;
  .d-comp {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    opacity: 0;
    background: black;
    // z-index: 99999;
  }
  .date-start {
    position: relative;
    input {
      @include ui-input($pl: 11px, $w: 265px);
    }
  }
  .date-pick {
    position: relative;
  }
}

.buttons-block {
   padding-bottom: 0;
   &__test {
     @include white-button;
     width: 100%;
     padding-left: 10px;
     padding-right: 10px;
     /* set the bottom to 0*/
     margin-top: 35px;
   }
 }

</style>
