const dev = false

// export const baseURL = 'REST_API_URL_REPLACE';
export const baseURL = dev ? 'http://localhost:8011/' : 'https://promoapi.shw-platform.com/'

export const taxesYearsList = [
    2023,
    2024,
    2025
]

export const SAVE_PAGES_FILTERS = false

export const cryptData = true;
export const cryptoKey = process.env.LANDING_CRYPTO_KEY || 'bVD4X3$P7QDLhmY^jLZZK@sw4rCX8CTS'
