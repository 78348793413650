<template>
  <div class="promotionPrize-modal"
    @close-modal="onModalClose"
  >

    <div class="promotionPrize-modal__inner">

      <!--div class="promotionPrize-modal__header">
        <p>{{ $t('ProductText.title') }}</p>
      </div-->

      <!-- Prize Content -->
      <div class="scrollableContainer">

        <!-- Prize General Information - DONE -->
        <div class="step__content">
          <div class="step__inner">
            <div class="step__inner-header">
              <p>{{ $t('PrizeText.generalInformation') }}</p>
            </div>

            <div>

              <div class="grid-container">

                <div class="step__item textarea-prize required large py-2">
                  <p class="step__item-text">
                    {{ $t('PrizeText.full_name') }}
                  </p>
                  <input class="step__item-input" type="text"
                         required oninvalid="this.setCustomValidity('Введите значение')" oninput="this.setCustomValidity('')"
                         v-model="prizeData.full_name" :disabled="!(editPermission)">
                </div>

                <div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.prize_example') }}
                  </p>
                  <div class="dd object dd-example-filter">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="prizeExamples"
                        :reduce="(option) => option.id"
                        label="name"
                        :clearable="true"
                        v-model="examplePrize"
                        @option:selected="setPrizeSelectedData"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button"
                        @click="openSelectOptions('.dd-example-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item textarea-prize required large py-2">
                  <p class="step__item-text">
                    {{ $t('PrizeText.name_req') }}
                  </p>
                  <input class="step__item-input" type="text"
                         required oninvalid="this.setCustomValidity('Введите значение')" oninput="this.setCustomValidity('')"
                         v-model="prizeData.name" :disabled="!(editPermission)">
                </div>

                <div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.type') }}
                  </p>
                  <div class="dd object dd-type-filter">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="PrizesTypes()"
                        :reduce="(option) => option.type"
                        label="label"
                        :clearable="false"
                        v-model="prizeData.type"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button"
                        @click="openSelectOptions('.dd-type-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item  textarea required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.prize_category_number') }}
                  </p>
                  <input class="step__item-input" type="number" min="0"  required
                         v-model="prizeData.categoryNumber" :disabled="!(editPermission)">
                </div>

                <!--div class="step__item textarea required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.category') }}
                  </p>
                  <input class="step__item-input" type="text"
                         required oninvalid="this.setCustomValidity('Введите значение')" oninput="this.setCustomValidity('')"
                         v-model="prizeData.category" :disabled="!(editPermission)">
                </div-->
                <div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.category') }}
                  </p>
                  <div class="dd object dd-category-filter required">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="allCategories"
                        v-model="prizeData.category"
                        :clearable="false"
                        :rules="[requiredSelectRules]"
                        :class="{ 'error--text--select': (prizeData.category == null || prizeData.category === '') }"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button required"
                        @click="openSelectOptions('.dd-category-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div>

                <div class="step__item  textarea required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.count') }}
                  </p>
                  <input class="step__item-input" id="count" type="text" name="prize_count" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prizeData.count" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea required">
                  <p class="step__item-text">
                    {{ `${$t('PrizeText.price')} (${($t('PrizeText.price_total_simple'))}: ${totalPrizeValue})` }}
                  </p>
                  <input class="step__item-input" type="text" name="prize_price" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prizeData.price" :disabled="!(editPermission)">
                </div>

                <!--div class="step__item textarea required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.price_total') }}
                  </p>
                  <input class="step__item-input" type="text"
                         :value="totalPrizeValue" :disabled="true">
                </div-->

              </div>


              <div class="grid-container">

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.prizesQuantityPerPersonPerPromo') }}
                  </p>
                  <input class="step__item-input" type="text" name="prizesQuantityPerPersonPerPromo" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prizeData.prizesQuantityPerPersonPerPromo" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.prizesQuantityPerPersonPerDay') }}
                  </p>
                  <input class="step__item-input" type="text" name="prizesQuantityPerPersonPerDay" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prizeData.prizesQuantityPerPersonPerDay" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.prizesQuantityPerPersonPerWeek') }}
                  </p>
                  <input class="step__item-input" type="text" name="prizesQuantityPerPersonPerWeek" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prizeData.prizesQuantityPerPersonPerWeek" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.prizesQuantityPerPersonPerMonth') }}
                  </p>
                  <input class="step__item-input" type="text" name="prizesQuantityPerPersonPerMonth" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prizeData.prizesQuantityPerPersonPerMonth" :disabled="!(editPermission)">
                </div>

              </div>


              <div class="grid-container">

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.applicationsQuantityPerPromo') }}
                  </p>
                  <input class="step__item-input" type="text" name="applicationsQuantityPerPromo" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prizeData.applicationsQuantityPerPromo" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.applicationsQuantityPerDay') }}
                  </p>
                  <input class="step__item-input" type="text" name="applicationsQuantityPerDay" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prizeData.applicationsQuantityPerDay" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.applicationsQuantityPerWeek') }}
                  </p>
                  <input class="step__item-input" type="text" name="applicationsQuantityPerWeek" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prizeData.applicationsQuantityPerWeek" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.applicationsQuantityPerMonth') }}
                  </p>
                  <input class="step__item-input" type="text" name="applicationsQuantityPerMonth" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prizeData.applicationsQuantityPerMonth" :disabled="!(editPermission)">
                </div>

              </div>

            </div>

          </div>
        </div>

        <!-- Prize Certificates Information -->
        <div
            class="step__content vertical-space"
            v-if="prizeData && prizeData.type === PrizesType.CERTIFICATE"
        >
          <div class="step__inner">
            <div class="step__inner-header">
              <p>{{ $t('PrizeText.prizeCertificatesData') }}</p>
            </div>

            <div>

              <div class="grid-container">

                <div class="step__item select">
                  <div class="new-promotion__checkbox">
                    <input
                        :disabled="!editPermission"
                        type="checkbox" id="surname"
                        v-model="prizeData.useOnlineCertificates" />
                    <label for="surname">{{ $t('PrizeText.useOnlineCertificates') }}</label>
                  </div>
                </div>

                <div v-if="prizeData.useOnlineCertificates" class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.loadedCertificatesCount') }}
                  </p>
                  <input class="step__item-input" type="number" min="0" name="pointsQuantity" required
                         :value="prizeData.certificates ? prizeData.certificates : 0" disabled>
                </div>
                <div v-else class="step__item select"/>


                <div v-if="prizeData.useOnlineCertificates" class="buttons-block">
                  <div
                      class="buttons-block__test"
                      @click="getNewCertificates"
                  >
                    <p class="buttons-block__test-text">
                      {{ $t('PrizeText.getNewCertificates') }}
                    </p>
                  </div>
                </div>
                <div v-else class="step__item select"/>

                <div v-if="prizeData.useOnlineCertificates" class="buttons-block">
                  <div
                      class="buttons-block__test"
                      @click="addNewCertificates"
                  >
                    <p class="buttons-block__test-text">
                      {{ $t('PrizeText.addNewCertificates') }}
                    </p>
                  </div>
                </div>
                <div v-else class="step__item select"/>

              </div>

            </div>

          </div>
        </div>

        <!-- Prize Conditions Information -->
        <div
            class="step__content vertical-space"
            v-if="prizeData"
        >
          <div class="step__inner">
            <div class="step__inner-header">
              <p>{{ $t('PrizeText.prizeConditions') }}</p>
            </div>

            <div>


              <div class="grid-container">

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.requiredScore') }}
                  </p>
                  <input class="step__item-input" type="text" name="pointsQuantity" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prizeData.pointsQuantity" :disabled="!(editPermission)">
                </div>

                <div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.actionForPrize') }}
                  </p>
                  <div class="dd object dd-actionForPrize-filter">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="ActionsForPrize()"
                        :reduce="(option) => option.action"
                        label="label"
                        :clearable="false"
                        v-model="prizeData.actionForPrize"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button"
                        @click="openSelectOptions('.dd-actionForPrize-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div>

                <div class="step__item select">
                </div>

                <div class="step__item select">
                </div>

                <!--div class="step__item select">
                  <p class="step__item-text">
                    {{ $t('PrizeText.advancedPrize') }}
                  </p>
                  <div class="dd object dd-advancedPrize-filter">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="Actions()"
                        :reduce="(option) => option.action"
                        label="label"
                        :clearable="false"
                        v-model="prizeData.advancedPrize"
                    >
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button"
                        @click="openSelectOptions('.dd-advancedPrize-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div-->

                <!--div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.actionsForAdvancePrize') }}
                  </p>
                  <div class="dd object dd-actionsForAdvancePrize-filter">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="ActionsForPrize()"
                        :reduce="(option) => option.action"
                        label="label"
                        :clearable="false"
                        v-model="prizeData.actionsForAdvancePrize"
                    >
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button"
                        @click="openSelectOptions('.dd-actionsForAdvancePrize-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div-->

              </div>

            </div>

          </div>
        </div>

        <!-- Prize Drawing Information -->
        <div
            class="step__content vertical-space"
            v-if="prizeData"
        >
          <div class="step__inner">

            <div class="page-header">
              <p class="step__inner-header">{{ $t('PrizeText.drawingConfigurations') }}</p>

              <div class="buttons-block">
                <div
                    class="page-header__newcode"
                    @click="openModalDeterminingInformation(null)"
                    style="width: 310px;"
                >
                  <p class="page-header__newcode-text">
                    {{ $t('ListItemText.add_determining_full') }}
                  </p>
                </div>
              </div>
            </div>

            <div>

              <div class="grid-container">

                <!--div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.automaticalApplicatonForPromo') }}
                  </p>
                  <div class="dd object dd-automaticalApplicatonForPromo-filter">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="Actions()"
                        :reduce="(option) => option.action"
                        label="label"
                        :clearable="false"
                        v-model="prizeData.automaticalApplicatonForPromo"
                    >
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button"
                        @click="openSelectOptions('.dd-automaticalApplicatonForPromo-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div-->

                <div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.automaticalDelivery') }}
                  </p>
                  <div class="dd object dd-automaticalDelivery-filter">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="Actions()"
                        :reduce="(option) => option.action"
                        label="label"
                        :clearable="false"
                        v-model="prizeData.automaticalDelivery"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button"
                        @click="openSelectOptions('.dd-automaticalDelivery-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div>

                <div class="step__item textarea required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.awardingPrizeTimeInDays') }}
                  </p>
                  <input class="step__item-input" type="text" name="awardingPrizeTimeInDays" required
                         oninput="this.value = this.value.replace(/[^0-9]/g, ''); this.setCustomValidity('')"
                         oninvalid="this.setCustomValidity('Введите значение')"
                         v-model="prizeData.awardingPrizeTimeInDays" :disabled="!(editPermission)">
                </div>

                <div class="step__item textarea">
                  <p class="step__item-text">
                    {{ $t('PrizeText.comission') }}
                  </p>
                  <input class="step__item-input" type="number" min="0" max="100"
                         v-model="prizeData.comission" :disabled="!(editPermission)">
                </div>

                <div class="step__item select required">
                </div>

              </div>

              <div class="grid-container">

                <div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.winnerDetermining') }}
                  </p>
                  <div class="dd object dd-determining-filter required">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="allDetermining"
                        :reduce="(option) => option.id"
                        label="name"
                        v-model="prizeData.winnerDetermining"
                        :clearable="true"
                        :rules="[requiredSelectRules]"
                        :class="{ 'error--text--select': (prizeData.winnerDetermining == null || prizeData.winnerDetermining === '') }"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button required"
                        @click="openSelectOptions('.dd-determining-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div>

                <div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.deletePointsAfterDelivery') }}
                  </p>
                  <div class="dd object dd-deletePointsAfterDelivery-filter">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="Actions()"
                        :reduce="(option) => option.action"
                        label="label"
                        :clearable="false"
                        v-model="prizeData.deletePointsAfterDelivery"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button"
                        @click="openSelectOptions('.dd-deletePointsAfterDelivery-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div>

                <div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.paymentDirection') }}
                  </p>
                  <div class="dd object dd-paymentDirection-filter">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="PaymentDirections()"
                        :reduce="(option) => option.direction"
                        label="label"
                        :clearable="false"
                        v-model="prizeData.paymentDirection"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button"
                        @click="openSelectOptions('.dd-paymentDirection-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div>

                <div class="step__item select required">
                  <p class="step__item-text">
                    {{ $t('PrizeText.drawingType') }}
                  </p>
                  <div class="dd object dd-drawingType-filter">
                    <v-select
                        :placeholder="$t('FieldsText.select_from_list')"
                        :options="DrawingTypes()"
                        :reduce="(option) => option.type"
                        label="label"
                        :clearable="false"
                        v-model="prizeData.drawingType"
                        :rules="[requiredSelectRules]"
                        :class="{ 'error--text--select': (isEmpty(prizeData.drawingType)) }"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template #no-options="{ search, searching, loading }">
                        {{ $t('Errors.missing_options') }}
                      </template>
                    </v-select>
                    <div
                        class="dd-button"
                        @click="openSelectOptions('.dd-drawingType-filter .vs__dropdown-toggle')"
                    ></div>
                  </div>
                </div>

              </div>

              <!-- Everyday Drawing Configuration -->
              <div v-if="prizeData.drawingType === DrawingType.EVERYDAY">

                <div class="title-part">
                  <p class="step__inner-header" style="margin-top:10px;">
                    {{ $t('PromotionText.everyday_drawing_settings') }}
                  </p>
                </div>

                <div class="grid-container">

                  <div class="step__item period">
                    <p class="step__item-text">
                      {{ $t('PrizeText.applicatonForPromoDates') }}
                    </p>
                    <!-- <input class="step__item-period" type="text" placeholder="Дата начала">
                    <input class="step__item-period" type="text" placeholder="Дата завершения"> -->
                    <date-time-picker
                        :start="prizeData.drawingEveryday.applicatonForPromoStartedAt"
                        :end="prizeData.drawingEveryday.applicatonForPromoEndedAt"
                        :required="true"
                        mode="dateTime"
                        @updateDates="updateDatesApplicatonForPromo"
                    />
                  </div>

                  <!-- Drawing Dates -->
                  <div class="step__item period">
                    <p class="step__item-text">
                      {{ $t('PrizeText.drawingPromoDates') }}
                    </p>
                    <!-- <input class="step__item-period" type="text" placeholder="Дата начала">
                    <input class="step__item-period" type="text" placeholder="Дата завершения"> -->
                    <date-time-picker
                        :start="prizeData.drawingEveryday.drawingPromoStartedAt"
                        :end="prizeData.drawingEveryday.drawingPromoEndedAt"
                        :required="true"
                        mode="dateTime"
                        @updateDates="updateDatesDrawingForPromo"
                    />
                  </div>

                  <!-- Drawing Date -->
                  <!--div class="step__item textarea required">
                    <p class="step__item-text">
                      {{ $t('DrawingText.drawingPromoDate') }}
                    </p>
                    <div class="date-picker">
                      <div class="date-start" style="width: 265px;">
                        <input
                            :disabled="!editPermission"
                            :value="parseFullTime(prizeData.drawingEveryday.drawingPromoAt)"
                            class="step__item-input"
                            :placeholder="$t('DrawingText.drawingPromoDateRules')"
                        />
                        <Datepicker
                            :disabled="!editPermission"
                            v-model="prizeData.drawingEveryday.drawingPromoAt"
                            class="d-comp"
                            valueType="format"
                            locale="ru"
                            autoApply
                            hideInputIcon
                            closable
                            time-picker
                            :clearable="false"
                            :selectText="$t('Buttons.ok')"
                            :cancelText="$t('Buttons.cancel')"
                        ></Datepicker>
                      </div>
                    </div>
                  </div-->

                  <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PrizeText.numberOfPrizes') }}
                    </p>
                    <input class="step__item-input" type="number" min="1" :max="prizeData ? prizeData.count ? prizeData.count : 0 : 0"
                           v-model="prizeData.drawingEveryday.numberOfPrizes" :disabled="!(editPermission)">
                  </div>

                  <div class="step__item select">
                    <div class="new-promotion__checkbox">
                      <input
                          :disabled="!editPermission"
                          type="checkbox" id="moveRemainingPrizesToNextDrawing"
                          v-model="prizeData.drawingEveryday.moveRemainingPrizesToNextDrawing" />
                      <label for="moveRemainingPrizesToNextDrawing">{{ $t('PrizeText.moveRemainingPrizesToNextDrawing') }}</label>
                    </div>
                  </div>

                </div>

              </div>

              <!-- Specific Dates Drawing Configuration -->
              <div v-else-if="prizeData.drawingType === DrawingType.SPECIFIC_DATES">

                <div class="page-header" style="margin-top:18px; margin-left: 10px; margin-bottom: -2px;">
                  <p class="step__inner-header">
                    {{ $t('PromotionText.specific_dates_drawing_settings') }}
                  </p>
                  <div class="buttons-block">
                    <div class="page-header__outload">
                      <p class="page-header__outload-text" @click="addNewDrawing">
                        {{ $t('PromotionText.new_specific_dates_drawing') }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="grid-container-5" v-for="data in prizeData.drawingSpecificDates" :key="data">

                  <div class="step__item period">
                    <p class="step__item-text">
                      {{ $t('PrizeText.applicatonForPromoDates') }}
                    </p>
                    <!-- <input class="step__item-period" type="text" placeholder="Дата начала">
                    <input class="step__item-period" type="text" placeholder="Дата завершения"> -->
                    <date-time-picker
                        :start="data.applicatonForPromoStartedAt"
                        :end="data.applicatonForPromoEndedAt"
                        :object="data"
                        :required="true"
                        mode="dateTime"
                        @updateDates="updateDatesForDrawing"
                    />
                  </div>

                  <!-- Drawing Date -->
                  <div class="step__item textarea required">
                    <p class="step__item-text">
                      {{ $t('DrawingText.drawingPromoDate') }}
                    </p>
                    <div class="date-picker">
                      <div class="date-start" style="width: 265px;">
                        <input
                            :disabled="!editPermission"
                            :value="parseFullTime(data.drawingPromoAt)"
                            class="step__item-input"
                            :placeholder="$t('DrawingText.drawingPromoDateRules')"
                        />
                        <Datepicker
                            :disabled="!editPermission"
                            v-model="data.drawingPromoAt"
                            class="d-comp"
                            valueType="format"
                            locale="ru"
                            autoApply
                            hideInputIcon
                            closable
                            :clearable="false"
                            :selectText="$t('Buttons.ok')"
                            :cancelText="$t('Buttons.cancel')"
                        ></Datepicker>
                      </div>
                    </div>
                  </div>

                  <div class="step__item textarea">
                    <p class="step__item-text">
                      {{ $t('PrizeText.numberOfPrizes') }}
                    </p>
                    <input class="step__item-input" type="number" min="1" :max="prizeData ? prizeData.count ? prizeData.count : 0 : 0"
                           v-model="data.numberOfPrizes" :disabled="!(editPermission)">
                  </div>

                  <div class="step__item select">
                    <div class="new-promotion__checkbox">
                      <input
                          :disabled="!editPermission"
                          type="checkbox" id="moveRemainingPrizesToNextDrawing"
                          v-model="data.moveRemainingPrizesToNextDrawing" />
                      <label for="moveRemainingPrizesToNextDrawing">{{ $t('PrizeText.moveRemainingPrizesToNextDrawing') }}</label>
                    </div>
                  </div>

                  <div class="step__item" style="width: 30px;">
                    <div class="table__cell button">
                      <div v-if="editPermission" class="clickable-button" style="padding-bottom: 5px;" @click="deleteDrawingData(data)">
                        <img
                            :title="$t('Buttons.delete')"
                            src="@/assets/img/icon_delete.svg"
                            alt="notification avatar">
                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>

        <!-- Prize Games Information -->
        <div
            class="step__content vertical-space"
            v-if="prizeData"
        >
          <div class="step__inner">

            <div class="page-header">
              <p class="step__inner-header">{{ $t('PrizeText.gamesConfigurations') }}</p>

              <div class="buttons-block">
                <div class="page-header__outload">
                  <p class="page-header__outload-text" @click="addNewGame">
                    {{ $t('ListItemText.add_game') }}
                  </p>
                </div>
              </div>
            </div>

            <div>

              <!-- Specific Games Configuration -->
              <div>

                <div class="grid-container-2" v-for="data in prizeData.games" :key="data">

                  <div class="step__item select required width-full">
                    <p class="step__item-text">
                      {{ $t('GameText.header') }}
                    </p>
                    <div :class="`dd object dd-game_${data.id}-filter required`">
                      <v-select
                          :placeholder="$t('FieldsText.select_from_list')"
                          :options="allGames"
                          :reduce="(option) => option.id"
                          label="name"
                          v-model="data.id"
                          :clearable="true"
                          :rules="[requiredSelectRules]"
                          :class="{ 'error--text--select': isEmpty(data.id) }"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                        <template #no-options="{ search, searching, loading }">
                          {{ $t('Errors.missing_options') }}
                        </template>
                      </v-select>
                      <div
                          class="dd-button required"
                          @click="openSelectOptions(`.dd-game_${data.id}-filter .vs__dropdown-toggle`)"
                      ></div>
                    </div>
                  </div>


                  <div class="step__item" style="width: 30px;">
                    <div class="table__cell button">
                      <div v-if="editPermission" class="clickable-button" style="padding-bottom: 5px;" @click="deleteGameData(data)">
                        <img
                            :title="$t('Buttons.delete')"
                            src="@/assets/img/icon_delete.svg"
                            alt="notification avatar">
                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>

      </div>

      <!-- Buttons -->
      <div class="promotionPrize-modal__buttons-block">
          <div v-if="editPermission" class="promotionPrize-modal__download-button" @click="addPrize">
              <p>{{ $t('Buttons.save') }}</p>
          </div>
          <div
              class="promotionPrize-modal__back-button"
              @click="close"
          >
              <p>{{ $t('Buttons.close') }}</p>
          </div>
      </div>
    </div>
  </div>

  <input id="getNewCertificates"
         class="file_input pointer"
         type="file" name="fields[assetFieldHandle][]"
         hidden
         @change="onGetNewCertificates" ref="get_certificates_file" accept="text/csv" />

  <input id="addNewCertificates"
         class="file_input pointer"
         type="file" name="fields[assetFieldHandle][]"
         hidden
         @change="onAddNewCertificates" ref="add_certificates_file" accept="text/csv" />

</template>

<script>
import { hasContent, isEmpty } from "@/tools/dataTools";
import { parseFullTime } from "@/tools/dateTools";

import DragDrop from "@/mixins/dragDropEvents";
import InputsMixin from "@/mixins/inputsEvent";

import {
  NewPrize,
  PrizesFromBody,
  PrizeRequiredVerify,

  Actions,
  ActionsForPrize,
  PaymentDirections,

  PrizesType,

  PrizesTypes,
  PrizesTypeText,

  DrawingType,
  DrawingTypes
} from "@/models/prizes";

import { mapActions, mapGetters } from 'vuex'

import DateTimePicker from '@/components/DateTimePicker'

export default {
  name: 'ModalEditPromotionPrize',
  emits: [
    "add",
    "save",
    "close",
    "openModalDeterminingInformation",
  ],
  components: {
    DateTimePicker
  },
  props: {
    editPermission: Boolean,
    item: Object,
    prize: Object,
  },
  mixins: [InputsMixin, DragDrop],
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {
      // types and statuses
      PrizesType,
      DrawingType,

      // dialog statuses
      isLoading: false,

      prizeData: NewPrize(),

      examplePrize: null,

      requiredSelectRules: [(v) => !!v || 'Выберите значение'],
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    prize: function (newValue, oldValue) {
      if (!newValue) {
        this.prizeData = NewPrize()
      } else {
        this.prizeData = PrizesFromBody(this.prize)
      }
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  mounted() {
    if (this.prize) {
      this.prizeData = PrizesFromBody(this.prize)
    }
  },
  computed: {
    allPrizes() {
      return this.GET_LIST_ITEMS().prizes ? this.GET_LIST_ITEMS().prizes : [];
    },
    prizeExamples() {
      let items = this.allPrizes;

      let response = []
      items.map(v => response.push({id: v.id, name: v.info.name}));

      return response
    },
    allCategories() {
      let items = [
        this.$t('PrizeCategories.guaranteed'),
        this.$t('PrizeCategories.daily'),
        this.$t('PrizeCategories.weekly'),
        this.$t('PrizeCategories.monthly'),
        this.$t('PrizeCategories.main')
      ]

      return items
    },
    allGames() {
      let items = this.GET_LIST_GAMES();
      items = JSON.parse(JSON.stringify(items))

      return items
    },
    totalPrizeValue() {
      return this.prizeData ? parseInt(this.prizeData.price ? this.prizeData.price : 0) * parseInt(this.prizeData.count ? this.prizeData.count : 0) : 0
    },
    allDetermining() {
      let items = this.GET_LIST_ITEMS().determining ? this.GET_LIST_ITEMS().determining : [];
      items = JSON.parse(JSON.stringify(items))

      items.map((item) => {
        item.name = item.info.name
      })

      return items
    },
    determiningExamples() {
      let items = this.allDetermining;

      let response = []
      items.map(v => response.push(v.link));

      return response
    },
  },
  methods: {
    ...mapGetters(["GET_LIST_ITEMS", 'GET_LIST_GAMES']),
    ...mapActions(["UPLOAD_PRIZE_FILES"]),

    PrizesTypes,
    PrizesTypeText,

    Actions,
    ActionsForPrize,
    PaymentDirections,
    DrawingTypes,

    isEmpty,
    hasContent,

    parseFullTime,

    addPrize() {
      console.log()
      if(this.prizeData == null) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.error_wrong_data'),
        });
      }

      const { success, datesSuccess, certificatesSuccess } = PrizeRequiredVerify(this.prizeData);

      if(success) {
        if(!datesSuccess) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.end_date_less_start_date'),
          });
        }
        else if(!certificatesSuccess) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('PrizeText.miss_certificates_or_count_is_not_enough'),
          });
        }
        // all is good
        else {
          // add a new object
          if(isEmpty(this.prizeData.id)) {
            this.createPrize()
          }
          // update the object
          else {
            this.updatePrize()
          }
        }
      }
      else {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.missing_required_data'),
        });
      }
    },

    createPrize() {
      this.$emit('add', this.prizeData);
    },

    updatePrize() {
      this.$emit('save', this.prizeData);
    },

    close() {
      this.prizeData = NewPrize();
      this.$emit('close');
    },

    openModalDeterminingInformation(determining) {
      this.$emit("openModalDeterminingInformation", determining)
    },

    onModalClose() {
    },

    setPrizeSelectedData() {
      if(hasContent(this.examplePrize)) {
        let selectedPrize = this.allPrizes.find(p => p.id === this.examplePrize)

        if(hasContent(selectedPrize)) {
          this.prizeData.name = selectedPrize.info.name;
          this.prizeData.category = selectedPrize.info.category;
          this.prizeData.type = selectedPrize.info.type;
          this.prizeData.price = selectedPrize.info.price;
          this.prizeData.comission = selectedPrize.info.comission;
        }
      }
    },

    getNewCertificates() {
      // no any used certificate - just ask to replace existing data
      if (confirm(this.$t('PrizeText.replaceCertificateFilesConfirmation'))) {
        document.getElementById('getNewCertificates').click()
      }
    },

    async onGetNewCertificates() {
      let file = this.$refs.get_certificates_file.files[0];

      // prepare csv file
      let response = await this.uploadPrizeFile(file, true)

      if(response && response.success) {
        // update number of prizes certificates
        this.prizeData.certificates = response.count
      }
    },

    addNewCertificates() {
      document.getElementById('addNewCertificates').click()
    },

    async onAddNewCertificates() {
      let file = this.$refs.add_certificates_file.files[0];

      // prepare csv file
      let response = await this.uploadPrizeFile(file, false)

      if(response && response.success) {
        // update number of prizes certificates
        this.prizeData.certificates = response.count
      }
    },

    updateDatesApplicatonForPromo(from, to) {
      if(this.prizeData) {
        this.prizeData.drawingEveryday.applicatonForPromoStartedAt = from
        this.prizeData.drawingEveryday.applicatonForPromoEndedAt = to
      }
    },

    updateDatesDrawingForPromo(from, to) {
      if(this.prizeData) {
        this.prizeData.drawingEveryday.drawingPromoStartedAt = from
        this.prizeData.drawingEveryday.drawingPromoEndedAt = to
      }
    },

    updateDatesForDrawing(from, to, object) {
      if(this.prizeData && this.prizeData.drawingSpecificDates) {
        let item = this.prizeData.drawingSpecificDates.find((item) => item === object)

        if(item) {
          item.applicatonForPromoStartedAt = from
          item.applicatonForPromoEndedAt = to
        }
      }
    },

    addNewDrawing() {
      if (this.item) {
        if(!this.checkDrawingData()) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.missing_required_data'),
          });

          return
        }

        if(!this.prizeData.drawingSpecificDates)
          this.prizeData.drawingSpecificDates = []

        this.prizeData.drawingSpecificDates.push({
          // date
          applicatonForPromoStartedAt: null,
          // date
          applicatonForPromoEndedAt: null,
          // date
          drawingPromoAt: null,

          // integer
          numberOfPrizes: 1,

          // bool
          moveRemainingPrizesToNextDrawing: false,
        })
      }
    },

    checkDrawingData() {
      let success = true

      if(!this.prizeData.drawingSpecificDates)
        this.prizeData.drawingSpecificDates = []

      for (let i = 0; i < this.prizeData.drawingSpecificDates.length; i++) {
        let item = this.prizeData.drawingSpecificDates[i]
        if (
            isEmpty(item.applicatonForPromoStartedAt) ||
            isEmpty(item.applicatonForPromoEndedAt) ||
            isEmpty(item.drawingPromoAt) ||
            isEmpty(item.numberOfPrizes) ||
            isEmpty(item.moveRemainingPrizesToNextDrawing)
        )
          success = false
      }


      return success
    },

    deleteDrawingData(data) {
      if (this.prizeData && data) {
        if(!this.prizeData.drawingSpecificDates)
          this.prizeData.drawingSpecificDates = []

        this.prizeData.drawingSpecificDates = this.prizeData.drawingSpecificDates.filter((item) => item !== data)
      }
    },

    addNewGame() {
      if (this.item) {
        if(!this.checkGameData()) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.missing_required_data'),
          });

          return
        }

        if(!this.prizeData.games)
          this.prizeData.games = []

        this.prizeData.games.push({
          // string
          id: null
        })
      }
    },

    checkGameData() {
      let success = true

      if(!this.prizeData.games)
        this.prizeData.games = []

      for (let i = 0; i < this.prizeData.games.length; i++) {
        let item = this.prizeData.games[i]
        if (isEmpty(item.id))
          success = false
      }


      return success
    },

    deleteGameData(data) {
      if (this.prizeData && data) {
        if(!this.prizeData.games)
          this.prizeData.games = []

        this.prizeData.games = this.prizeData.games.filter((item) => item !== data)
      }
    },

    async uploadPrizeFile(file, replace) {
      return new Promise(resolve => {
        if(file == null) {
          this.$notify({
            type: "error",
            title: this.$t('Errors.error_not_ok'),
            text: this.$t('Errors.error_read_file'),
          });

          resolve({success: false});
        }
        else {
          this.$notify({
            id: 'uploadPrizesCertificates',
            duration: 9999999999,
            type: "notification",
            title: this.$t('Errors.wait_finish'),
            text: this.$t('PrizeText.prepare_doc_started'),
          });
        }

        let formData = new FormData();

        formData.append("file", file);
        formData.append("promotion", this.item ? this.item.id : null);
        formData.append("prize", this.prizeData ? this.prizeData.id : null);
        formData.append("replace", replace ? replace : false);

        this.isLoading = true

        this.UPLOAD_PRIZE_FILES( {
          promotion: this.item ? this.item.id : null,
          prize: this.prizeData ? this.prizeData.id : null,
          replace: replace ? replace : false,
          file: formData,
        }).then((res) => {
          this.isLoading = false
          this.$notify.close('uploadPrizesCertificates');

          if (res.success) {
            this.$notify({
              type: "success",
              title: this.$t('Errors.success_operation'),
              text: this.$t('Errors.file_is_prepared'),
            });

            resolve({success: true, count: res.count});
          } else {
            this.$notify({
              type: "error",
              title: this.$t('Errors.error_not_ok'),
              text: this.$t('Errors.error_try_again'),
            });

            resolve({success: false});
          }
        });

      });
    },

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.promotionPrize-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 45px 78px 25px 45px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 2px #CED5F480;
    z-index: 2;

    .promotionPrize-modal__header {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      margin-top: -20px;
      margin-bottom: 20px;
    }

    &__dragAndDrop {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      //width: 600px;
      height: 270px;
      margin-bottom: 25px;
      padding: 67px 67px 67px;
      border: 1px dotted $purple;
      border-radius: 10px;
      background-color: $SideBlocksColor;
      cursor: pointer;
      svg {
          margin: 0 auto 35px;
      }
    }

    &__dragAndDrop-text {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      color: $black;
    }

    &__buttons-block {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    &__download-button {
        width: 190px;
        height: 35px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
        color: $white;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
    }

    &__back-button {
        width: 190px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $purple;
        font-size: 16px;
        border: 1px solid $purple;
        border-radius: 5px;
        cursor: pointer;
    }
}

.scrollableContainer {
  padding: 0;
  max-height: 70vh;
  overflow: scroll;
  overflow-x: hidden;
}

.photo-preview {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: 70px;
    height: 70px;
    cursor: pointer;
    margin-bottom: 20px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  &__img {
    width: 70px;
    height: 70px;
  }
}

.upload-file {
  margin-bottom: 5px;
  cursor: no-drop;
}

.download-file {
  margin-bottom: 5px;
  cursor: context-menu;
}

.file_input {
  margin-bottom: -47px;
  width: inherit;
  height: inherit;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  //padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.dd {
  position: relative;
  width: 100%;
  &.object {
    width: 265px !important;
    margin-top: 0px !important;
    font-size: 12px;
    font-weight: 500;
    // .dd-button {
    //   height: 27px !important;
    // }
  }
  .dd-button {
    @include ui-dropdown-button();
    border-radius: 0px 5px 5px 0px;
  }
  & ::v-deep .vs__dropdown-toggle {
    border: 1px solid $inputCaption;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
}

.multiline_left {
  white-space: pre-wrap;
  text-align: start;
}


</style>
