<template>
  <div class="store-modal"
    @close-modal="onModalClose"
  >

    <div class="store-modal__inner">

      <div class="store-modal__header">
        <p>{{ $t('StoreText.title') }}</p>
      </div>

      <!-- Content -->
      <div class="grid-container-1">

        <div class="step__item textarea required width-full">
          <p class="step__item-text">
          {{ $t('FieldsText.title_req_label') }}
          </p>
          <input class="step__item-input" type="text"
                 required oninvalid="this.setCustomValidity($t('FieldsText.title_label_input'))" oninput="this.setCustomValidity('')"
                 v-model="storeDate.name" :disabled="!(editPermission)">
        </div>

        <div class="step__item textarea required width-full">
          <p class="step__item-text">
          {{ $t('StoreText.inn_req_label') }}
          </p>
          <input class="step__item-input" type="text" name="inn" maxlength="12"
                 required oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                 v-model="storeDate.inn" :disabled="!(editPermission)">
        </div>

      </div>


      <!-- Buttons -->
      <div class="store-modal__buttons-block">
          <div v-if="editPermission" class="store-modal__download-button" @click="addStore">
              <p>{{ $t('Buttons.save') }}</p>
          </div>
          <div
              class="store-modal__back-button"
              @click="close"
          >
              <p>{{ $t('Buttons.close') }}</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {isEmpty} from "@/tools/dataTools";

import DragDrop from "@/mixins/dragDropEvents";
import InputsMixin from "@/mixins/inputsEvent";

import {
  NewStore,
  StoreFromBody,
  StoreRequiredVerify
} from "@/models/stores";


export default {
  name: 'ModalEditStore',
  components: {
  },
  props: {
    editPermission: Boolean,
    store: Object,
  },
  mixins: [InputsMixin, DragDrop],
  delimiters: ['${', '}'], // Avoid Twig conflicts
  data() {
    return {
      isLoading: false,

      storeDate: NewStore(),
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    store: function (newValue, oldValue) {
      if (!newValue) {
        this.storeDate = NewStore()
      } else {
        this.storeDate = StoreFromBody(this.store)
      }
    },
    isLoading() {
      if(this.isLoading)
        this.$progress.start();
      else
        this.$progress.finish();
    },
  },
  mounted() {
    if (this.store) {
      this.storeDate = StoreFromBody(this.store)
    }
  },
  computed: {
  },
  methods: {
    addStore() {
      console.log()
      if(this.storeDate == null) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.error_wrong_data'),
        });
      }
      else if(!StoreRequiredVerify(this.storeDate)) {
        this.$notify({
          type: "error",
          title: this.$t('Errors.error_not_ok'),
          text: this.$t('Errors.missing_required_data'),
        });
      }
      else if(isEmpty(this.storeDate.id)) {
        this.createStore()
      }
      else {
        this.updateStore()
      }
    },

    createStore() {
      this.$emit('add', this.storeDate);
    },

    updateStore() {
      this.$emit('save', this.storeDate);
    },

    close() {
      this.storeDate = NewStore();
      this.$emit('close');
    },

    onModalClose() {
    }

  }
}
</script>

<style lang="scss" src="@/assets/scss/common.scss"></style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";

.store-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 45px 78px 25px 45px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 2px #CED5F480;
    z-index: 1;

    .store-modal__header {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      margin-top: -20px;
      margin-bottom: 20px;
    }

    &__dragAndDrop {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      //width: 600px;
      height: 270px;
      margin-bottom: 25px;
      padding: 67px 67px 67px;
      border: 1px dotted $purple;
      border-radius: 10px;
      background-color: $SideBlocksColor;
      cursor: pointer;
      svg {
          margin: 0 auto 35px;
      }
    }

    &__dragAndDrop-text {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      color: $black;
    }

    &__buttons-block {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    &__download-button {
        width: 190px;
        height: 35px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #8F21C9 0%, #8F21C9 0.01%, #4640B9 100%);
        color: $white;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
    }

    &__back-button {
        width: 190px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $purple;
        font-size: 16px;
        border: 1px solid $purple;
        border-radius: 5px;
        cursor: pointer;
    }
}

.photo-preview {
  display: flex;
  flex-wrap: wrap;
  &__item {
    width: 70px;
    height: 70px;
    cursor: pointer;
    margin-bottom: 20px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  &__img {
    width: 70px;
    height: 70px;
  }
}

.upload-file {
  margin-bottom: 5px;
  cursor: no-drop;
}

.download-file {
  margin-bottom: 5px;
  cursor: context-menu;
}

.file_input {
  margin-bottom: -47px;
  width: inherit;
  height: inherit;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  //padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.dd {
  position: relative;
  width: 100%;
  &.object {
    width: 265px !important;
    margin-top: 0px !important;
    font-size: 12px;
    font-weight: 500;
    // .dd-button {
    //   height: 27px !important;
    // }
  }
  .dd-button {
    @include ui-dropdown-button();
    border-radius: 0px 5px 5px 0px;
  }
  & ::v-deep .vs__dropdown-toggle {
    border: 1px solid $inputCaption;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
}

.multiline_left {
  white-space: pre-wrap;
  text-align: start;
}


</style>
